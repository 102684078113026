import WebService from "./WebService";
 
class TemplatesService {
	constructor() {
		this.webService = new WebService();
	}

	getTemplates(callback) {
		this.webService.call('get', `private/v1/templates`,{})
		.then(response => {
			let templates = [];
			if (response.status === 200){
				templates = response.data.templates;
			}
			callback(templates);
		})
		.catch(error =>{
			console.log(error);
		})
	}
}

export default TemplatesService;