import "../styles.scss";
import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import MessagesDailyCalendar from '../components/MessagesDailyCalendar';
import ScheduleCreate from '../components/ScheduleCreate';
import ScheduleService from '../services/SchedulesService';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Moment from 'moment';
import { ALERT_ERROR } from '../constants/alert';
import TemplatesService from '../services/TemplatesService';
import { FormControl, FormLabel, Tab, Tabs } from '@mui/material';

class MessagesAutomationPage extends React.Component {
	constructor(props) {
		super(props);
		this.services = {
			schedule: new ScheduleService(),
			template: new TemplatesService(),
		} 
		this.state = {
			selectedDate: new Date(),
			tab: 0,
			alert: {
				open: false,
				message: '',
				type: 'info',
			},
			grid: {
				rows: [],
				selectedRows: []
			},
			dialog: {
				open: false,
			},
			templates: [],
		};

		this.handleTabsChange = this.handleTabsChange.bind(this);
		this.handleChangeShowDate = this.handleChangeShowDate.bind(this);
		this.dailySchedulesRef = React.createRef();
		this.startProps();
	}

	handleTabsChange(event, value) {
		this.setState({ tab: value })
	}

	startProps = () => {
		this.handleChangeShowDate(this.state.selectedDate);
		this.services.template.getTemplates(this.getTemplates);
	}

	getTemplates = (templates) =>{
		this.setState({ templates: templates });
	}

	handleChangeShowDate = (value) => {
		this.setState({ selectedDate: value });
		this.services.schedule.scheduleByDay(Moment(value).format('YYYY-MM-DDT00:00:00Z'), this.callbackGridRows);
	}

	callbackGridRows = (rows) => {
		this.setState({ grid: { rows: rows, selectedRows: [] }})
		if (this.dailySchedulesRef.current!= null){
			this.dailySchedulesRef.current.setGridRows(this.state.grid.rows);
		}
	}

	callbackGridSelectedRows = (selectedRows) => {
		this.setState({ grid: { rows: this.state.rows, selectedRows: selectedRows }})
	}
	
	handleClickOpen = () => {
		this.setState({ dialog: { open: true }});
	};

	handleClose = () => {
		this.setState({ dialog: { open: false }});
	};

	handleCloseAccept = () => {
		if (this.state.grid.selectedRows.length>0){
			const schedules = this.state.grid.selectedRows.map(row => {
				return {
					id: row.id,
				}
			})
			this.services.schedule.deleteSchedules(schedules, this.callbackDeleteSchedules);
		}else{
			this.displayMessage("Nenhum agendamento selecionado", ALERT_ERROR);
			this.setState({ dialog: {open: false}});
		}
	};

	callbackDeleteSchedules = (message, type) => {
		this.displayMessage(message, type);
		this.setState({ dialog: {open: false}});
		this.handleChangeShowDate(this.state.selectedDate);
	}

	callbackAddSchedule = (message, type) => {
		this.displayMessage(message, type);
		this.handleChangeShowDate(this.state.selectedDate);
	}

	displayMessage = (message, type) => {
		this.setState(() => ({
			alert: {
				message: message, 
				open: true, 
				type: type,
			},
			refreshGrid: true,
		}));
	}
	
	render() {
		return (
			<React.Fragment>
				<Tabs value={this.state.tab} onChange={this.handleTabsChange}>
					<Tab label="Disparo de mensagens" value={0} />
				</Tabs>
				<div role="tabpanel" hidden={this.state.tab !== 0}>
					<Box sx={{ w: '100%', m: 2 }}>
						<Grid
							container
							sx={{ mt: 1 }}
							spacing={1}
						>
							<Grid item xs={1}></Grid>
							<Grid item xs={10} >
								<Collapse in={this.state.alert.open}>
									<Alert
									className="alert"
									severity={this.state.alert.type}
									action={
										<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											this.setState(() => ({
												alert: {
													type: this.state.alert.type,
													open: false,
												}
											}));
										}}
										>
										<CloseIcon fontSize="inherit" />
										</IconButton>
									}
									sx={{ mb: 2 }}
									>
									{this.state.alert.message}
									</Alert>
								</Collapse>
							</Grid>
							<Grid item xs={1}></Grid>

							<Grid item xs={1}></Grid>
							<Grid item xs={3}>
								<Box className="formBox">
									<FormControl className="formControl form-control-schedule-date" variant="standard">
										<FormLabel id="schedule-date-label">Data do disparo</FormLabel>
										<DatePicker
											id="schedule-date"
											aria-labelledby="schedule-date-label"
											minDate={new Date()}
											maxDate={new Date()}
											openTo="day"
											views={['year', 'month', 'day']}
											value={this.state.selectedDate}
											onChange={this.handleChangeShowDate}
											renderInput={(params) => <TextField {...params}
												style={{ marginBottom: '10px' }}
												onLoad={this.handleChangeShowDate} />}
										/>
									</FormControl>
								</Box>

								<ScheduleCreate
									callback={this.callbackAddSchedule}
									actualDate={this.state.selectedDate}
									templates={this.state.templates} 
								/>
								
								<FormControl className="formControl">
									<Button onClick={this.handleClickOpen} style={{ backgroundColor: "#DC143C", color: "#FFF", marginTop: "5px", display: this.state.hideCancelButton }}>
										Derrubar Agendamento
									</Button>
								</FormControl>

							</Grid>
							<Grid item xs={7}>
								<div style={{ height: 650, width: '100%' }}>
									<MessagesDailyCalendar
										ref={this.dailySchedulesRef}
										rows={this.state.grid.rows}
										callbackSelectedRows={this.callbackGridSelectedRows} />

									<Dialog
										open={this.state.dialog.open}
										onClose={this.handleClose}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<DialogTitle id="alert-dialog-title">
											Você tem certeza que deseja excluir os agendamentos selecionados?
										</DialogTitle>
										<DialogContent>
										<DialogContentText id="alert-dialog-description">
											CUIDADO! Esse procedimento é irreversível.
										</DialogContentText>
										</DialogContent>
										<DialogActions>
										<Button onClick={this.handleClose}>Voltar</Button>
										<Button onClick={this.handleCloseAccept} autoFocus>
											Aceitar
										</Button>
										</DialogActions>
									</Dialog>
								</div>
							</Grid>
							<Grid item xs={1}></Grid>
						</Grid>
					</Box>
				</div>
				
			</React.Fragment>
		);
	}
}

export default MessagesAutomationPage;
