import { createTheme } from '@mui/material/styles';

const getTheme = function() {
    return createTheme({
        palette: {
            primary: {
                light: "#3d4ed7",
                main: "#3d4ed7",
                dark: "#3d4ed7"
            },
            secondary: {
                light: "#f3897e",
                main: "#f3897e",
                dark: "#f3897e"
            },
            neutral: {
                light: "#3d4ed7",
                main: "#3d4ed7",
                dark: "#3d4ed7"
            }
        },
        typography: {
            fontFamily: [
                "Halcyon",
                "Arial",
                "sans-serif"
            ].join(','),
        }
    });
}

export default getTheme;