import { useCookies } from "react-cookie"
import {COOKIE_ACCESS_TOKEN} from "@olaisaac/sorting-hat";
import {createContext, useEffect, useState} from "react";
import SortingHatService from "../services/SortingHatService";
import Loading from "../components/Loading";

export const AuthContext = createContext(false);

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [cookies] = useCookies([COOKIE_ACCESS_TOKEN]);

    const sortingHatService = new SortingHatService();

    useEffect(() => {
        const checkAuth = async () => {
            if (process.env.REACT_APP_SKIP_AUTH_MIDDLEWARE === "true"){
                setIsAuth(true);
                return;
            }
            if ([true, undefined].includes(sortingHatService.client.isExpired(cookies[COOKIE_ACCESS_TOKEN]))) {
                return sortingHatService.login()
            }
            setIsAuth(true);
        }
        if (!isAuth) {
            checkAuth();
        }
    },[])

    if (!isAuth) {
        return Loading();
    }

    return <AuthContext.Provider value={isAuth}>{children}</AuthContext.Provider>
}