import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import Moment from 'moment';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { TemplateRadioType } from '../constants/schedule';

class MessagesDailyCalendar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			selectedRows: [],
		}
	}
	
	setGridRows(rows) {
		const result = rows.map(schedule => {

			const time = Moment(schedule.scheduled_time).format('HH:mm')
			const startTime = schedule.start_time ? Moment(schedule.start_time).format('HH:mm'): ''
			const finishedTime = schedule.finished_time ? Moment(schedule.finished_time).format('HH:mm') : ''

			const hasCampaign = !!schedule.campaign

			const type = hasCampaign ? TemplateRadioType.SpecificDescription : TemplateRadioType.DynamicDescription
			const template = hasCampaign ? schedule.campaign.template.external_id : '-'
			const templateId = hasCampaign ? schedule.campaign.template_id : null

			return {
				id: schedule.id,
				time: time,
				start_time: startTime,
				finished_time: finishedTime,
				msg_count: schedule.messages_capacity,
				type: type,
				template: template,
				template_id: templateId,
			}

		})

		this.setState({ rows: result });
   }

	render() {
		return (
			<DataGrid
				rowHeight={50}
				hideFooter
				sx={{ p: 1 }}
				columns={[
					{ 
						field: 'time', 
						headerName: "Horário",
						flex: 2,
					},
					{
						field: 'type',
						headerName: "Tipo",
						flex: 3,
					},
					{
						field: 'template',
						headerName: "Template",
						flex: 5,
					},
					{
						field: 'msg_count',
						headerName: "Nº Mensagens",
						flex: 2,
						align: "right",
						renderCell: (params) => (
							<>
								<span>{params.value}</span>
								<Link target={'_blank'} 
									className={'externalLinkDatagrid'}
									title={'Clique para mais detalhes'}
									href={`${process.env.REACT_APP_METABASE_URL}6474-sil-scheduled-messages?schedule=${params.id}`}>
									<AnalyticsIcon fontSize="inherit" />
								</Link>
							</>
						)
					}
				]}
				checkboxSelection
				isRowSelectable={
					(params) => Moment(params.row.time, "HH:mm").isAfter(Moment())
				}
				rows={this.state.rows}
				onSelectionModelChange={(ids) => {
					const selectedIDs = new Set(ids);
					const selectedRowData = this.state.rows.filter((row) =>
						selectedIDs.has(row.id.toString())
					);
					this.setState({ selectedRows: selectedRowData });
					this.props.callbackSelectedRows(selectedRowData);
				}}	
			/>
		)
	}
}

export default MessagesDailyCalendar;
