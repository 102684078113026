import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => (
  <Overlay>
    <CircularProgress />
  </Overlay>
);

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(0, 0%, 100%, 0.8);
`;

export default Loading;
