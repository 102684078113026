import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExitIcon from '@mui/icons-material/ExitToApp';
import CookieUtils from '../utils/cookie';

const cookieUtils = new CookieUtils();

const NavBar = () => {
	return (
		<AppBar position="static" >
			<Container maxWidth="xl" className="header">
				<Toolbar disableGutters>
				<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
					>
						<span>isaac&nbsp;</span>- SIL
					</Typography>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
					>
						<IconButton
							className="exitButton"
							aria-label="close"
							color="inherit"
							size="small"
							title="Sair"
							onClick={() => {
								cookieUtils.clearAllCookies();
								sessionStorage.clear();
								window.location.reload();
							}}
							>
							<ExitIcon fontSize="inherit" />
							<span>Sair</span>
						</IconButton>
					</Typography>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default NavBar;
