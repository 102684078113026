class CookieUtils {

    clearAllCookies = function() {
        document.cookie.replace(/(?<=^|;).+?(?=\\=|;|$)/g,
        // eslint-disable-next-line no-restricted-globals
        name => location.hostname
            .split(/\.(?=[^\.]+\.)/)
            .reduceRight((acc, val, i, arr) => i ? arr[i]='.' + val + acc : (arr[i]='', arr), '')
            .map(domain => document.cookie=`${name}=;max-age=0;path=/;domain=${domain}`)
        );
    }

}

export default CookieUtils;
