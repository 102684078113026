import React from 'react';
import { Box, Typography } from '@mui/material';

class Forbidden extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '100vh',
					}}
					className='IsaacColor'
					>
					<Typography variant="h4">
						Infelizmente você não tem permissão para acessar esta página :(
					</Typography>
				</Box>
			</React.Fragment>
		);
	}
}	

export default Forbidden;
