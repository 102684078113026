import * as React from 'react';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, FormControlLabel, InputLabel, Input, FormLabel, Radio, RadioGroup, FormHelperText, ListItemIcon, Link } from '@mui/material';
import ScheduleService from '../services/SchedulesService';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import {TemplateRadioType, EmptyInputValue, RequiredFieldErrorMessage, HourRegex} from "../constants/schedule";

const TemplateFieldName = "template";
const HourFieldName = "hour";
const CapacityFieldName = "capacity";

const InputHourMaskCustom = React.forwardRef(function InputHourMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			autoComplete='off'
			mask="00:00"
			definitions={{
				'#': /[1-9]/,
			}}
			inputRef={ref}
			onAccept={(value) => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

InputHourMaskCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

const InputCapacityMaskCustom = React.forwardRef(function InputCapacityMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			autoComplete='off'
			mask="00000"
			definitions={{
				'#': /[1-9]/,
			}}
			inputRef={ref}
			onAccept={(value) => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

InputCapacityMaskCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

class ScheduleCreate extends React.Component {

	constructor(props) {
		super(props);
		this.state = this.InitialState()
		this.state.hiddeError = false
		this.state.templateRadioType = TemplateRadioType.SpecificId
	}

	InitialState = () => {
		return {
			values: {
				momentMask: '',
				numberformat: '1320',
			},
			msgCount: '',
			templateId: EmptyInputValue,
			fields: {},
			errors: {}
		};
	}

	clearState = () => {
		this.setState({hiddeError: true})
		this.setState(this.InitialState())
		this.setState({hiddeError: false})
	}

	handleCreateScheduleClick = () => {

		if (!this.handleFormValidation()) {
			return;
		}

		const dateFormat = `YYYY-MM-DDT${this.state.values.momentMask}:00Z`
		const scheduledTime = new Date(Moment(this.props.actualDate).format(dateFormat))

		let selectedCampaign = null

		if (this.state.templateId != EmptyInputValue) {
			selectedCampaign = {
				template_id: this.state.templateId,
				template:{
					id: this.state.templateId
				}
			}
		}

		const data = {
			messages_capacity: parseInt(this.state.msgCount),
			scheduled_time: Moment(scheduledTime).format("YYYY-MM-DDTHH:mm:00Z"),
			campaign: selectedCampaign,
			can_create_campaign: true,
		}
		new ScheduleService().addSchedules(data, (message, alertType) => {
			this.props.callback(message, alertType);
			this.clearState();
		}, (message, alertType) => {
			this.props.callback(message, alertType);
		});
	}

	setFieldChange(field, event) {
		let fields = this.state.fields;
		fields[field] = event.target.value;
		this.setState({ fields });
	}

	handleChangeTemplateRadioButton = (event) => {
		this.setState({ templateRadioType: event.target.value });
		this.setState({ templateId: EmptyInputValue });
	};

	handleChangeTemplate = (event) => {
		this.setState({ templateId: event.target.value });
		this.setFieldChange(TemplateFieldName, event)
		const hasError = !this.isTemplateValid(event.target.value);
		this.setErrorState(TemplateFieldName, hasError);
	};

	handleChangeHour = (event) => {
		this.setState({ values: { momentMask: event.target.value, numberformat: '1320'}})
		this.setFieldChange(HourFieldName, event)
		const hasError = !this.isHourValid(event.target.value) && !this.state.hiddeError;
		this.setErrorState(HourFieldName, hasError);
	};

	handleChangeCapacity = (event) => {
		this.setState({ msgCount: event.target.value });
		this.setFieldChange(CapacityFieldName, event)
		const hasError = !this.isCapacityValid(event.target.value)  && !this.state.hiddeError;
		this.setErrorState(CapacityFieldName, hasError);
	};

	hasFieldChange = (fields, fieldName) => {
		return !!fields[fieldName]
	}

	setErrorState = (fieldName, hasError) => {
		let errors = this.state.errors;
		errors[fieldName] = hasError;
		this.setState({ errors: errors });
	}

	isTemplateValid = (templateValue) => {
		return templateValue != EmptyInputValue;
	}

	isHourValid = (hourValue) => {
		return HourRegex.test(hourValue)
	}

	isCapacityValid = (capacityValue) => {
		return parseInt(capacityValue) > 0
	}

	handleTemplateValidation = () => {
		let fields = this.state.fields;

		const hasError = !this.hasFieldChange(fields, TemplateFieldName)
			|| !this.isTemplateValid(fields[TemplateFieldName]);
		this.setErrorState(TemplateFieldName, hasError);

		return !hasError;
	}

	handleHourValidation = () => {
		let fields = this.state.fields;

		const hasError = ((!this.hasFieldChange(fields, HourFieldName)
		    || !this.isHourValid(fields[HourFieldName])));
		this.setErrorState(HourFieldName, hasError);

		return !hasError;
	}

	handleCapacityValidation = () => {
		let fields = this.state.fields;

		const hasError = !this.hasFieldChange(fields, CapacityFieldName)
			|| !this.isCapacityValid(fields[CapacityFieldName]);
		this.setErrorState(CapacityFieldName, hasError);

		return !hasError;
	}

	handleFormValidation = () => {
		let templateValidationResult = true;
		const hourValidationResult = this.handleHourValidation();
		const capacityValidationResult = this.handleCapacityValidation();

		if (this.state.templateRadioType === TemplateRadioType.SpecificId) {
			templateValidationResult = this.handleTemplateValidation();
		}

		return templateValidationResult && hourValidationResult && capacityValidationResult;
	}

	render() {
		return (
			<Box
			  sx={{
				'& > :not(style)': {
				  m: 1,
				},
			  }}
			  className="formBox form-box-schedule-fields"
			>

				<FormControl className="formControl " variant="standard">
					<FormLabel id="radio-button-template-label">Que tipo de template deseja configurar?</FormLabel>
					<RadioGroup
						aria-labelledby="radio-button-template-label"
						name="radio-button-template"
						value={this.state.templateRadioType}
						onChange={this.handleChangeTemplateRadioButton}
					>
						<FormControlLabel value={TemplateRadioType.SpecificId} className="radio-item" control={<Radio />} label={TemplateRadioType.SpecificDescription} />
						<FormControlLabel value={TemplateRadioType.DynamicId} className="radio-item" control={<Radio />} label={TemplateRadioType.DynamicDescription} />
					</RadioGroup>
				</FormControl>

				{ this.state.templateRadioType === TemplateRadioType.SpecificId && (
					<FormControl className="formControl" variant="standard" required error={this.state.errors[TemplateFieldName]}>
						<InputLabel id="template-select-label">Templates</InputLabel>
						<Select
							labelId="template-select-label"
							id="template-simple-select"
							value={this.state.templateId}
							onChange={this.handleChangeTemplate}
							>
								<MenuItem key="none" value={EmptyInputValue}></MenuItem>
								{this.props.templates.map((template) => (
									<MenuItem key={template.id} value={template.id}>

										<Link target={'_blank'}
												className={'externalLinkSelect'}
												title={'Clique para mais detalhes'}
												href={`${process.env.REACT_APP_METABASE_URL}6475-sil-template-messages?id=${template.external_id}`}>
												<ListItemIcon>
											<AnalyticsIcon fontSize="inherit" />
										</ListItemIcon>
										</Link>

										{template.external_id}

									</MenuItem>
								))}
						</Select>
						{this.state.errors[TemplateFieldName] && (
							<FormHelperText>{RequiredFieldErrorMessage}</FormHelperText>
						)}
					</FormControl>
				)}
				<FormControl className="formControl" variant="standard" required error={this.state.errors[HourFieldName]}>
					<InputLabel htmlFor="formatted-text-mask-input">Horário</InputLabel>
					<Input
						value={this.state.values.momentMask}
						onChange={this.handleChangeHour}
						name="momentMask"
						id="formatted-text-mask-input"
						inputComponent={InputHourMaskCustom}
					/>
					{this.state.errors[HourFieldName] && (
						<FormHelperText>{RequiredFieldErrorMessage}</FormHelperText>
					)}
				</FormControl>
		
				<FormControl className="formControl" variant="standard" required error={this.state.errors[CapacityFieldName]}>
					<InputLabel htmlFor="count-msg-schedule">Número de mensagens</InputLabel>
					<Input
						value={this.state.msgCount}
						onChange={this.handleChangeCapacity}
						name="countMsgSchedule"
						id="count-msg-schedule"
						inputComponent={InputCapacityMaskCustom}
					/>
					{this.state.errors[CapacityFieldName] && (
						<FormHelperText>{RequiredFieldErrorMessage}</FormHelperText>
					)}
				</FormControl>
		
				<FormControl className="formControl center">
					<Button variant="contained"
						onClick={this.handleCreateScheduleClick}>
							Criar
					</Button>
				</FormControl>
		
			</Box>
		  );
	}
}

export default ScheduleCreate;


