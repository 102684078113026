import axios from "axios"
import CookieUtils from "../utils/cookie";
import HttpStatus from "http-status-codes";

const cookieUtils = new CookieUtils();

class WebService {
	constructor(App) {
		this.host = process.env.REACT_APP_API_HOST;
		this.app = App;
	}
	
	call(method, url, data) {
		const axiosStatic = axios({
			method: method,
			url: this.host + url,
			headers: { 
				'Access-Control-Allow-Origin' : '*',
				'Access-Control-Allow-Methods':'POST,GET,OPTION,DELETE,UPDATE',
				'Authorization':'Basic '+ sessionStorage.getItem('token'),
			},
			timeout: 4000, 
			data: data
		});

		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			async function (error) {
				const { status } = error.response
				if (status === HttpStatus.UNAUTHORIZED) {
					sessionStorage.clear()
					cookieUtils.clearAllCookies()
					window.location.reload()
				} else if (status === HttpStatus.FORBIDDEN) {
					window.location.href = '/error/forbidden'
				}
				return Promise.reject(error)
			}
		);

		return axiosStatic;
	}
}
export default WebService;