import './styles.scss';
import React from 'react';

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import brLocale from 'date-fns/locale/pt-BR';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { CookiesProvider } from 'react-cookie';

import NavBar from './components/NavBar';
import SortingHatService from './services/SortingHatService';
import getTheme from './utils/theme';
import Router from './router';
import {AuthProvider} from "./providers/auth";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.loginService = new SortingHatService();
	}

	render() {
		return (
			<CookiesProvider>
				<AuthProvider>
					<DndProvider backend={HTML5Backend}>
						<LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
							<ThemeProvider theme={getTheme()}>
								<NavBar />
								<Router />
							</ThemeProvider>
						</LocalizationProvider>
					</DndProvider>
				</AuthProvider>
			</CookiesProvider>
		)
	}
}

export default App;
