import {AuthorizationClient} from '@olaisaac/sorting-hat';

class SortingHatService {
    constructor() {
        this.loginUrl = `${process.env.REACT_APP_SORTING_HAT_URL}/api/v2/auth/backoffice/sil/login`;
        this.client = new AuthorizationClient({
            baseURL: process.env.REACT_APP_SORTING_HAT_URL,
            appName: 'messages-generator',
        });
    }

	login() {
        window.location.href = this.loginUrl;
	}
}

export default SortingHatService;