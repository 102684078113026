import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import MessagesAutomationPage from './pages/messages-automation';
import Forbidden from "./pages/error/forbidden";

const Router = () => {
   return (
       <BrowserRouter>
            <Routes>
                <Route path="/" Component={ MessagesAutomationPage } />
                <Route path="/error/forbidden" Component={ Forbidden } />
            </Routes>   
       </BrowserRouter>
   )
}

export default Router;