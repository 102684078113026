import WebService from "./WebService";

import { ALERT_ERROR, ALERT_SUCCESS } from '../constants/alert'
 
class ScheduleService {
	constructor() {
		this.webService = new WebService();
	}

	scheduleByDay(day, callback) {
		this.webService.call('get', `private/v1/schedules?date=${day}`,{})
		.then(response => {
			let ret = []
			if (response.status === 200){
				ret = response.data.schedules
			}
			callback(ret);
		})
		.catch(error =>{
			console.log(error)
		})
	}

	deleteSchedules(schedules, callback){
		this.webService.call('delete', `private/v1/schedules`, schedules)
		.then(() => {
			callback("Agendamentos excluídos com sucesso", ALERT_SUCCESS);
		})
		.catch(error =>{
			if (error.response.status === 500) {
				callback(error.response.data, ALERT_ERROR);
			}else{
				callback(error.message, ALERT_ERROR);
			}
		})
	}

	addSchedules(data, successCallback, errorCallback){
		this.webService.call('post', `private/v1/schedules`, data)
		.then(() => {
			successCallback("Agendamento criado com sucesso", ALERT_SUCCESS);
		})
		.catch(error =>{
			if (error.response.status === 500) {
				errorCallback(error.response.data, ALERT_ERROR);
			}else{
				errorCallback(error.message, ALERT_ERROR);
			}
		})
	}
}

export default ScheduleService;